import About from "./Components/About/About";
import Contact from './Components/Contact/Contact';
import Experience from "./Components/Experience/Experience"; 
import Testimonials from "./Components/Testimonials/Testimonials";
import Profile from './Components/Profile/Profile';
import Navbar from './Components/Navbar/Navbar';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Services from "./Components/Services/Services";
import ParticlesBackground from "./Components/ParticlesBackground/ParticlesBackground";
import { useEffect } from "react";

const App = () => {
    
    useEffect(() => {
        document.title = "Uttam Kumar Mishra"
    }, [])
    
    return (
        <>
            <ParticlesBackground />
            <div>
                <Header />
                <Navbar />
                <About />
                <Experience />
                <Services />
                <Profile />
                <Testimonials />
                <Contact />
                <Footer />
        </div>

        </>  
        
    );
}
 
export default App;