import { BsLinkedin} from 'react-icons/bs';
import { FaGithub} from 'react-icons/fa';
// import { FiDribbble } from 'react-icons/fi';
import { FaAward } from 'react-icons/fa';


const HeaderSocials = () => {
    return (  
        <div className="header__socials">
            <a href="https://www.linkedin.com/in/uttam-mishra/" target="_blank"> 
                <BsLinkedin />
            </a>
            <a href="https://github.com/ukm202" target="_blank">
                <FaGithub />
            </a>
            <a href="https://nyuad.nyu.edu/en/news/latest-news/community-life/2020/september/bloom-like-a-flower.html" target="_blank">
                <FaAward />
            </a>


        </div>
    );
}
 
export default HeaderSocials;