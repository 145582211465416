import './Header.css';
import CTA from './CTA';
import ME from '../../Assests/me.jpeg';
import ME1 from '../../Assests/me1.jpeg';
import HeaderSocials from './HeaderSocials';


const Header = () => {
    return (  
        <header>
            <div className="container header__container">
                <h5>Hello I'm</h5>
                <h1>Uttam Kumar Mishra</h1>
                <h5 className="text-light job">Software Engineer - <a href="https://wio.io/" target='_blank'>Wio Bank PJSC</a></h5>
                <CTA />
                <HeaderSocials />
                <div className="me">
                    <img src={ME1} alt="My-profile" />
                </div>
                <a href="#contact" className="scroll__down">Scroll Down</a>
                
                
            </div>
        </header>
    );
}
 
export default Header;