import './Profile.css';
// import IMG1 from '../../Assests/IMG1.webp';
import IMG1 from '../../Assests/IMG01.jpeg';
import Imperial from '../../Assests/Imperial.png';

import IMG2 from '../../Assests/IMG02.jpeg';
import IMG3 from '../../Assests/IMG03.jpeg';


// import IMG2 from '../../Assests/IMG2.webp';
// import IMG3 from '../../Assests/IMG3.webp';
import IMG4 from '../../Assests/IMG4.webp';
import IMG5 from '../../Assests/IMG5.webp';
import IMG6 from '../../Assests/IMG6.webp';

const Profile = () => {
    const data = [
        {
            id: 1,
            image: IMG3,
            title: "Wio Bank",
            github: "https://wio.io/",
            demo: 'https://wio.io/'
        },
        {
            id: 2,
            image: Imperial,
            title: "Imperial Engineering Associates",
            github: "https://github.com/ukm202/Imperial-Engineering-Associates",
            demo: 'https://imperial-associates.com/'
        },
        {
            id: 3,
            image: IMG1,
            title: "Applied Machine Learning",
            github: "https://github.com/ukm202/Applied-Machine-Learning",
            demo: "https://github.com/ukm202/Applied-Machine-Learning",
        },
        
        
        {
            id: 4,
            image: IMG4,
            title: "Convert Natural Human Language to Python code",
            github: "https://github.com",
            demo: 'https://www.linkedin.com/in/uttam-mishra/'
        },
        {
            id: 5,
            image: IMG5,
            title: "Machine Learning Models Analyst",
            github: "https://github.com",
            demo: 'https://www.linkedin.com/in/uttam-mishra/'
        },
        {
            id: 6,
            image: IMG6,
            title: "Data Analysis Intern",
            github: "https://github.com",
            demo: 'https://www.linkedin.com/in/uttam-mishra/'
        },
    ]
    return (  
        <section id='profile'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio__container">
                {
                    data.map(({id,image,title,github,demo})=>{
                        return(
                            <article key={id} className="portfolio__item">
                                <div className="portfolio__item-image">
                                    <img src={image} alt={title} />
                                </div>
                                <h3>{title}</h3>
                                <div className="portfolio__item-cta">
                                    <a href={github} className='btn' target='_blank'>Github</a>
                                    <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                                </div>
                            </article>
                        )
                    })
                }

            </div>


        </section>
    );
}
 
export default Profile;