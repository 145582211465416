import './Footer.css';
import { FaFacebookF } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { IoLogoTwitter } from 'react-icons/io';


const Footer = () => {
    return (  
        <div>
            <footer>
                <a href="#" className="footer__logo">Uttam Kumar Mishra</a>
                <ul className="permalinks">
                    <li><a href="#">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#experience">Experience</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#profile">Portfolio</a></li>
                    <li><a href="#testimonials">Testimonials</a></li>
                    <li><a href="#contact">Contact</a></li>
                    
                </ul>

                <div className="footer__socials">
                    <a href="https://www.facebook.com/uttam.mishra.311" >
                        <FaFacebookF />
                    </a>
                    <a href="https://www.instagram.com/_uttam001/">
                        <BsInstagram />
                    </a>
                    <a href="https://www.twitter.com">
                        <IoLogoTwitter />
                    </a>

                </div>

                <div className="footer__copyright">
                    <small>&copy;2023 Uttam Kumar Mishra. All rights reserved.</small>
                </div>
                
            </footer>
        </div>
    );
}
 
export default Footer;