import './Testimonials.css';
import AVTR1 from '../../Assests/Chandan.jpeg';
import AVTR2 from '../../Assests/Himal.jpeg';
import AVTR3 from '../../Assests/Priti.jpeg';
import AVTR4 from '../../Assests/Ayush.png';

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';






const Testimonials = () => {
    const data = [
        {
            
            avatar: AVTR1,
            name: 'Chandan Mishra',
            review: 'I have known Uttam for last 24 years now. He is my brother of course. All my special moments and happiness start with his presence and end in his absence. As an older brother, I am full of pride and admiration for his maturity, dedication, humility, and hard work. Sometimes I only wish I can be as good as you are.'
        },
        {
            
            avatar: AVTR2,
            name: 'Himal Shrestha',
            review: 'Currently, I am working on Connected Library System with Uttam and I can attest to the fact that he is not just a good coder but a great team player.'
        },
        {
            
            avatar: AVTR3,
            name: 'Priti Jha',
            review: 'Uttam Mishra is very optimistic about everything and brings a lot of positive energy in work.'
        },
        {
            
            avatar: AVTR4,
            name: 'Ayush Pandey',
            review: 'Uttam has always been very inspiring and active member of our team. He keeps pushing us for the best. Needless to say, he is best when it comes to critical thinking and problem solving.'
        },
    ]
    return (  
        <section id='testimonials'>
            <h5>Reviews from Peers and Clients</h5>
            <h2>Testimonials</h2>
            <Swiper className="container testimonials__container"
                // install Swiper modules
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={40}
                slidesPerView={1}
                pagination={{ clickable: true }} 
                loop= {true}
                autoplay= {{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                
                
                
            

        >
                {
                    data.map(({avatar,name,review}, index)=>{
                        return(
                            <SwiperSlide key={index} className='testimonials'>
                                
                                <div className="client__avatar">
                                    <img src={avatar} alt={name} />
                                    
                                </div>
                                <h5 className='client__name'>{name}</h5>
                                <small className='client__review'>
                                    {review}
                                </small>
                            </SwiperSlide>
                        )
                        
                    })
                }
             
            </Swiper>
            
        </section>
    );
}
 
export default Testimonials;