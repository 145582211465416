import React from 'react';
import emailjs from 'emailjs-com';

const CTA = () => {
    const handleCVRequest = () => {
        alert('Kindly include the purpose for requesting the CV in your message. Thank you for your understanding!'); // Display a polite and professional message when the button is clicked
    };

    return (  
        <div className="cta">
            <a href="#contact" className='btn' onClick={handleCVRequest}>CV available upon request</a>
            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
    );
}

export default CTA;
