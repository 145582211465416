import "./About.css";
import ME from "../../Assests/me.jpeg";
import ME1 from "../../Assests/me1.jpeg";
import ME2 from "../../Assests/me2.jpeg";
import ME3 from "../../Assests/me3.jpeg";
import ME4 from "../../Assests/me4.jpeg";

import { FaHome, FaGraduationCap } from "react-icons/fa";

import { VscFolderActive } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME2} alt="My profile" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
          <article className="about__card">
              <FaHome className="about__icon" />
              <h5>Home</h5>
              <small>Rautahat, Nepal</small>
            </article>
            <article className="about__card">
              <FaGraduationCap className="about__icon" />
              <h5>Education</h5>
              <small>Computer Science</small>
            </article>
            

            {/* <article className='about__card'>
                            <FiUsers className='about__icon' />
                            <h5>Clients</h5>
                            <small>10+ Worldwide</small>
                        </article> */}

            <article className="about__card">
              <VscFolderActive className="about__icon" />
              <h5>Work</h5>
              <small><a href="https://wio.io/">Wio Bank PJSC</a></small>
            </article>
          </div>
          <p>
            Hi! My name is Uttam Mishra and I am delighted to introduce myself
            to you. I hail from the beautiful country, Nepal, known for its
            stunning landscapes and diverse cultures. I am a proud alumnus of
            New York University, where I pursued my Bachelor's degree in
            Computer Science with a minor in Applied Mathematics. I am currently
            working as a Software Engineer at{" "}
            <a href="https://wio.io/" target="_blank">Wio Bank PJSC</a> in the United Arab
            Emirates, the region's first platform bank that provides advanced
            banking solutions and aims to drive the growth of the country's
            digital economy.
          </p>
          <p>
             Beyond my professional pursuits, I am an avid sports enthusiast, relishing football and cricket. 
             In my leisure hours, I find delight in culinary experimentation, exploring diverse cuisines. 
             My overarching passion lies in continual learning and embracing novel experiences, consistently striving for excellence in all my endeavors.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
