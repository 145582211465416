import './Services.css';
import { BiCheck } from 'react-icons/bi';

const Services = () => {
    return (  
        <section id='services'>
            <h5>What I Offer</h5>
            <h2>Services</h2>
            <div className="container services__container">
                {/* Backend Development */}
                <article className="service">
                    <div className="service__head">
                        <h3>Backend Development</h3>
                    </div>
                    <ul className="service__list">
                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Write well-designed, testable, efficient code</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Integrate data from various back-end services and databases</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Maintain, expand, and scale websites</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Stay updated with emerging technologies and industry trends</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Create and maintain software documentation</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Collaborate in Agile Methodology</p>
                        </li>
                    </ul>
                </article>

                {/* Technical Proficiencies */}
                <article className="service">
                    <div className="service__head">
                        <h3>Technical Proficiencies</h3>
                    </div>
                    <ul className="service__list">
                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Proficient in using JIRA and Confluence for project management and collaboration</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Experience with Azure DevOps for comprehensive software development lifecycle management</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Utilize Kafka for real-time data streaming and event processing</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Employ Kibana for log and data visualization</p>
                        </li>
                    </ul>
                </article>

                {/* Data Science */}
                <article className="service">
                    <div className="service__head">
                        <h3>Data Science</h3>
                    </div>
                    <ul className="service__list">
                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Identify relevant data sources for business needs</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Collect structured and unstructured data</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Source missing data and preprocess it</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Organize data into usable formats</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Build predictive models and machine learning algorithms</p>
                        </li>

                        <li>
                            <BiCheck className='service__list-icon' />
                            <p>Evaluate model performance and refine algorithms</p>
                        </li>
                    </ul>
                </article>
            </div>
        </section>
    );
}
 
export default Services;
